import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../pictures/logo.png';
import LogOutIcon from '../Icons/LogOutIcon';
import UsersIcon from '../Icons/UsersIcon';
import ServiceListIcon from '../Icons/ServiceListIcon';
import CashIcon from '../Icons/CashIcon';
import TechnicianIcon from '../Icons/TechnicianIcon';
import Cookies from 'js-cookie';
import SettingsIcon from '../Icons/SettingsIcon';
import DownIcon from '../Icons/DownIcon';
import UpIcon from '../Icons/UpIcon';
import ParametersIcon from '../Icons/ParametersIcon';
import { Modules, useLicenses } from 'src/store/licenseContext';
import MaterialIcon from '../Icons/MaterialIcon';
import ContactIcon from '../Icons/ContactIcon';
import RefreshIcon from '../Icons/RefreshIcon';
import NotificationIcon from '../Icons/NotificationIcon';

const SidePanel: React.FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const { getIsModuleValid } = useLicenses();

  return (
    <div className='bg-primarySide min-h-screen content-between grid place-items-center'>
      <div className='flex-col grid place-items-center w-36'>
        <img src={logo} className='App-logo px-8 pt-6 w-72 flex items-center mb-4' alt='logo' />
        {getIsModuleValid(Modules.SERVICES) && (
          <div
            className={`text-white ntb:mb-2 ntb:mt-3 lg:mt-6 md:mt-3 w-full flex justify-center h-full items-center ${
              pathname === '/' || pathname.startsWith('/order') ? 'bg-layout rounded-lg ' : ''
            } `}
          >
            <Link to='/'>
              <ServiceListIcon />
              Seznam zakázek
            </Link>
          </div>
        )}
        {getIsModuleValid(Modules.CONTACTS) && (
          <div
            className={`text-white ntb:mb-2 ntb:mt-3 lg:mt-6 md:mt-3 w-full  flex justify-center h-full items-center ${
              pathname === '/contact' || pathname.startsWith('/contact') ? 'bg-layout rounded-lg ' : ''
            } `}
          >
            <Link to='/contact'>
              <ContactIcon />
              Zákazníci
            </Link>
          </div>
        )}
        {getIsModuleValid(Modules.ADMINISTRATION) && (
          <>
            <div className={'text-white mb-2 mt-4 w-full flex justify-center  h-full items-center cursor-pointer '}>
              <div onClick={() => setIsOpen(!isOpen)} className='flex'>
                Administrace
                {isOpen ? <UpIcon /> : <DownIcon />}
              </div>
            </div>
            {isOpen && (
              <div className='h-fit pl-1 pr-1 rounded-lg border-white bg-layout w-full'>
                <div
                  className={`text-white mb-2 mt-3 w-full flex justify-center ntb:h-16 h-20 items-center  ${
                    pathname.startsWith('/user') ? 'bg-selectedMenu rounded-lg ' : ''
                  } `}
                >
                  <Link to='/user'>
                    <UsersIcon />
                    Uživatelé
                  </Link>
                </div>

                <div
                  className={`text-white mb-2 mt-3 w-full flex justify-center ntb:h-16 h-20 items-center ${
                    pathname.startsWith('/payment-method') ? 'bg-selectedMenu rounded-lg ' : ''
                  } `}
                >
                  <Link to='/payment-method'>
                    <CashIcon />
                    Platební metody
                  </Link>
                </div>

                <div
                  className={`text-white mb-2 mt-3 w-full  flex justify-center ntb:h-16 h-20 items-center ${
                    pathname.startsWith('/technician') ? 'bg-selectedMenu rounded-lg ' : ''
                  } `}
                >
                  <Link to='/technician'>
                    <TechnicianIcon />
                    Technici
                  </Link>
                </div>

                <div
                  className={`text-white mb-2 mt-3 w-full  flex justify-center ntb:h-16 h-20 items-center ${
                    pathname.startsWith('/parameter') ? 'bg-selectedMenu rounded-lg ' : ''
                  } `}
                >
                  <Link to='/parameter'>
                    <ParametersIcon />
                    Parametry
                  </Link>
                </div>

                <div
                  className={`text-white pb-2 mt-3 w-full flex justify-center ntb:h-16 h-20 items-center ${
                    pathname.startsWith('/material') ? 'bg-primary rounded-lg ' : ''
                  } `}
                >
                  <Link to='/material'>
                    <MaterialIcon />
                    Materiál
                  </Link>
                </div>

                <div
                  className={`text-white mb-2 w-full text-center flex justify-center ntb:h-16 pb-20 mb-2 h-20 ${
                    pathname.startsWith('/routine-services') ? 'bg-primary rounded-lg' : ''
                  } `}
                >
                  <Link to='/routine-services'>
                    <div className='ml-12'>
                      <RefreshIcon />
                    </div>
                    Pravidelné servisy
                  </Link>
                </div>
                <div
                  className={`text-white mb-2 mt-3 w-full flex justify-center ntb:h-16 h-20 items-center ${
                    pathname.startsWith('/notification') ? 'bg-primary rounded-lg ' : ''
                  } `}
                >
                  <Link to='/notification'>
                    <NotificationIcon />
                    Notifikace
                  </Link>
                </div>

                <div
                  className={`text-white mb-2 mt-3 w-full flex justify-center ntb:h-16 h-20 items-center ${
                    pathname === '/settings' ? 'bg-selectedMenu rounded-lg ' : ''
                  } `}
                >
                  <Link to='/settings'>
                    <SettingsIcon />
                    Nastavení
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <button
        onClick={() => {
          Cookies.remove('token');
          navigate('/login');
        }}
      >
        <div className='text-white ntb:mb-4 mb-8 mt-4'>
          <LogOutIcon />
          Odhlásit
        </div>
      </button>
    </div>
  );
};

export default SidePanel;
