import React, { useCallback, useEffect, useState } from 'react';
import {
  SubmitHandler,
  useForm,
  Controller,
  SubmitErrorHandler,
  useFieldArray,
  FormProvider,
  useWatch,
} from 'react-hook-form';
import { isNil, remove } from 'ramda';
import { useOldApiClient } from 'src/api';
import { FinishOrderRequest } from '../../../api/types/technician/FinishOrderRequest';
import { PaymentMethodResponse } from 'src/api/types/paymentMethods/PaymentMethodResponse';
import MobileTextfield from 'src/components/MobileTextfield';
import TechnicianFinishPageCard from 'src/components/TechnicianFinishPageCard';
import HorizontalRow from 'src/components/HorizontalRow';
import TechnicianEditButton from 'src/components/TechnicianEditButton';
import TransparentButton from 'src/components/TransparentButton';
import Modal from 'src/components/Modal';
import logo from 'src/pictures/logo.png';
import CashField from 'src/components/CashField';
import { yupResolver } from '@hookform/resolvers/yup';
import serviceFormTechnicianSchema, { ServiceFormTechnicianValues } from './technicianSchema';
import { useNavigateWithSearch } from 'src/utils/useNavigateWithSearch';
import Signature from '../../../components/Signature/index';
import { PriceWatcher } from './PriceWatcher';
import SelectMobile from 'src/components/SelectMobile';
import BlackDownIcon from 'src/components/Icons/BlackDownIcon';
import BlackUpIcon from 'src/components/Icons/BlackUpIcon';
import MobileDescriptionField from 'src/components/MobileDescriptionField';
import ClearIcon from '../../../components/Icons/ClearIcon';
import ArrowLeft from 'src/components/Icons/ArrowLeft';
import ArrowRight from 'src/components/Icons/ArrowRight';
import BinIcon from 'src/components/Icons/BinIcon';
import { usePaymentMethods } from 'src/store/paymentMethodContext';
import { ParametersResponse } from 'src/api/types/parameters/ParametersResponse';
import { dotToComma } from 'src/utils/commonUtils';
import { Select as AutocompleteSelect } from '../../../components/SelectV2';
import { useMaterials } from 'src/store/materialsContext';
import TechnicianRowButton from 'src/components/TechnicianRowButton';
import Spinner from 'src/components/Spinner';
import Uploader from 'src/components/Uploader';
const Image: React.FC<{ id: number; className?: string }> = ({ id, className }) => {
  const [image, setImage] = useState<string>();
  const Client = useOldApiClient();

  const getFile = async (id: number): Promise<void> => {
    await Client.get(`file/${id}`, { responseType: 'blob' }).then((res) => {
      setImage(window.URL.createObjectURL(new Blob([res.data])));
    });
  };

  useEffect(() => {
    getFile(id);
  }, [id]);

  return image ? <img src={image} className={className} /> : <p>Načítání fotky...</p>;
};
interface TechnicianFinishFormProps {
  defaultValues?: Partial<ServiceFormTechnicianValues>;
}

const TechnicianOrderFinish: React.FC<TechnicianFinishFormProps> = () => {
  const navigate = useNavigateWithSearch();
  const Client = useOldApiClient();
  const { paymentMethods } = usePaymentMethods();
  const [isModalSucces, setIsModalSucces] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isOpenTechnicianSign, setIsOpenTechnicianSign] = useState(false);
  const [isOpenCustomerSign, setIsOpenCustomerSign] = useState(false);
  const [photoDetailToShow, setPhotoDetailToShow] = useState<number | undefined>();
  const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
  const [parameters, setParameters] = useState<ParametersResponse[]>([]);
  const { materials, setMaterials } = useMaterials();

  const form = useForm<ServiceFormTechnicianValues>({
    resolver: yupResolver(serviceFormTechnicianSchema),
    defaultValues: {
      materials: [
        {
          material: '',
          price: undefined,
          quantity: undefined,
          code: null,
        },
        {
          material: '',
          price: undefined,
          quantity: undefined,
          code: null,
        },
        {
          material: '',
          price: undefined,
          quantity: undefined,
          code: null,
        },
      ],
      descriptionNote: null,
      servicePaymentMethodId: null,
      status: 'COMPLETED',
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = form;

  useEffect(() => {
    setValue('servicePaymentMethodId', paymentMethods[0]?.id);
  }, [paymentMethods]);

  const { fields, append } = useFieldArray({
    name: 'materials',
    control,
  });

  const onSubmit: SubmitHandler<ServiceFormTechnicianValues> = async ({
    orderSignatureCustomer,
    orderSignatureTechnician,
    ...data
  }) => {
    const modifiedData = {
      ...data,
      materials: data?.materials.filter(
        ({ material, price, quantity }) => material !== '' && !isNil(material) && !isNil(price) && !isNil(quantity),
      ),
      filesIds: selectedFiles.length > 0 ? selectedFiles : null,
    };

    try {
      const files = new FormData();
      files.append('orderSignatureTechnician', orderSignatureTechnician);
      files.append('orderSignatureCustomer', orderSignatureCustomer);
      await Client.post('/technician-order/signature', files);

      const response = await Client.put('/technician-order', modifiedData);
      if (response.status === 200) {
        setIsModalSucces(true);
      }
    } catch (error) {
      console.error('test', error);
    }
  };

  const onError: SubmitErrorHandler<FinishOrderRequest> = (errors) => {
    console.log(errors);
  };

  const onUpload = async (file: File): Promise<void> => {
    const responses = await Client.post(
      'technician-order/file',
      { file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: '*/*',
        },
      },
    );

    const validResponseStatuses = [200, 201];

    if (validResponseStatuses.includes(responses.status)) {
      setSelectedFiles((prevState) => [...prevState, responses.data.id]);
    }
  };

  const getParameters = async (): Promise<void> => {
    const response = await Client.get('/parameters');

    setParameters(response.data);
  };

  const onWindowClose = useCallback((e: Event) => {
    e.preventDefault();
    e.returnValue = true;
  }, []);

  useEffect(() => {
    getParameters();
  }, []);

  useEffect(() => {
    if (isSubmitting) {
      window.addEventListener('beforeunload', onWindowClose);
      return () => {
        window.removeEventListener('beforeunload', onWindowClose);
      };
    }
  }, [isSubmitting]);

  const getMaterialList = async (): Promise<void> => {
    const response = await Client.get('/material');
    setMaterials(response.data);
  };

  useEffect(() => {
    getMaterialList();
  }, []);

  const findDPH = parameters.find((parameter) => parameter.paramKey === 'DPH')?.paramValue || '';
  const finalPriceWithoutDph = useWatch({ name: 'serviceTotalPrice', control }) as unknown as string;
  const finalPriceWithoutDphAsNumber = parseFloat(finalPriceWithoutDph);
  const DPH = parseFloat(findDPH);
  const finalPriceWithDph = ((DPH * finalPriceWithoutDphAsNumber) as number) / 100 + finalPriceWithoutDphAsNumber;

  return (
    <div className='bg-background md:flex lg:flex md:justify-center lg:justify-center ntb:justify-center ntb:flex'>
      <div className='grid md:w-10/12 lg:w-10/12 ntb:w-10/12'>
        <div className='flex justify-center mb-2'>
          <img src={logo} className='App-logo px-8 pt-6 w-40' alt='logo' />
        </div>

        <FormProvider {...form}>
          <form className='grid place-items-center' onSubmit={handleSubmit(onSubmit, onError)}>
            <PriceWatcher />
            <TechnicianFinishPageCard>
              <div className='flex space-x-44 mb-2 mt-4'>
                <div className='font-bold text-text text-2xl ml-4'>Dokončení zakázky</div>
              </div>
              <div className='text-lightGrey text-sm ml-4'>
                Dodatečné informace, které se doplňují poté co je zakázka provedena.
              </div>
              <HorizontalRow />
              <div className='grid grid-cols-2 mb-4 items-center'>
                <div className='font-bold text-text ml-2 mr-3 mb-2 ml-10'>Práce*</div>
                <div className='grid grid-row-2'>
                  <div className='mt-2 flex'>
                    <Controller
                      control={control}
                      name='serviceDuration'
                      render={({ field, fieldState }) => (
                        <>
                          <MobileTextfield {...field} error={fieldState.error?.message} />
                        </>
                      )}
                    />

                    <div className='p-2 lg:ml-4 ntb:ml-4'> hodin</div>
                  </div>
                </div>
              </div>
              <HorizontalRow />
              <div className='lg:grid lg:grid-cols-2 md:grid-cols-4 ntb:grid ntb:grid-cols-2 mb-4 '>
                <div className='font-bold text-text ml-2 mr-3 ml-10'>Materiál</div>
                <div className='grid'>
                  <div className='grid grid-cols-4 md:ml-10'>
                    <div className='sm:ml-2'>Kód</div> <div>Materiál</div> <div>Počet</div>
                    <div className='ml-2'>Cena/Ks</div>{' '}
                  </div>

                  {fields.map((field, index) => {
                    return (
                      <div className='grid grid-cols-4 mb-2 gap-x-2' key={field.id}>
                        <div className='md:pl-10 sm:ml-2'>
                          <Controller
                            control={control}
                            name={`materials.${index}.code`}
                            render={({ field }) => (
                              <AutocompleteSelect
                                options={materials}
                                getOptionLabel={(opt) => `${opt.materialCode}`}
                                getOptionValue={(opt) => opt.materialCode}
                                createOnEmpty
                                {...field}
                                onChange={(_, opt, search) => {
                                  if (search != null) {
                                    field.onChange(search);
                                    return;
                                  }
                                  setValue(`materials.${index}.price`, opt?.materialValue ?? null);
                                  setValue(`materials.${index}.material`, opt?.materialName ?? null);
                                  field.onChange(_);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className='md:ml-6'>
                          <Controller
                            control={control}
                            name={`materials.${index}.material`}
                            render={({ field }) => (
                              <AutocompleteSelect
                                options={materials}
                                getOptionLabel={(opt) => `${opt.materialName}`}
                                getOptionValue={(opt) => opt.materialName}
                                createOnEmpty
                                {...field}
                                onChange={(_, opt, search) => {
                                  if (search != null) {
                                    field.onChange(search);
                                    return;
                                  }
                                  setValue(`materials.${index}.price`, opt?.materialValue ?? null);
                                  setValue(`materials.${index}.code`, opt?.materialCode ?? null);
                                  field.onChange(_);
                                }}
                              />
                            )}
                          />
                        </div>

                        <Controller
                          control={control}
                          name={`materials.${index}.quantity`}
                          render={({ field, fieldState }) => <CashField {...field} error={fieldState.error?.message} />}
                        />
                        <div className='mr-2'>
                          <Controller
                            control={control}
                            name={`materials.${index}.price`}
                            render={({ field, fieldState }) => (
                              <CashField {...field} error={fieldState.error?.message} />
                            )}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className='flex justify-center '>
                    <TransparentButton
                      type='button'
                      onClick={() => append({ material: '', price: null, quantity: null, code: null })}
                    >
                      Nový materiál
                    </TransparentButton>
                  </div>
                </div>
              </div>
              <HorizontalRow />
              <div className='grid grid-cols-2 mb-4 items-center'>
                <div className='font-bold text-text ml-2 mr-3 mb-2 ml-10'>Poznámka</div>
                <div className='grid grid-row-2'>
                  <div className='mt-2 flex'>
                    <Controller
                      control={control}
                      name='descriptionNote'
                      render={({ field }) => (
                        <>
                          <MobileDescriptionField {...field} />
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <HorizontalRow />
              <div className='grid grid-cols-2 mb-4 items-center'>
                <div className='font-bold text-text ml-2 mr-3 mb-2 ml-10'>Doprava</div>
                <div className='grid grid-row-2'>
                  <div className='mt-2 flex'>
                    <Controller
                      control={control}
                      name='serviceMileage'
                      render={({ field, fieldState }) => (
                        <>
                          <MobileTextfield {...field} error={fieldState.error?.message} />
                        </>
                      )}
                    />
                    <div className='p-2 lg:ml-9 lg:ntb-9 ml-5'> km</div>
                  </div>
                  <div className='mt-2 flex'>
                    <Controller
                      control={control}
                      name='serviceTimeOnRoad'
                      render={({ field, fieldState }) => (
                        <>
                          <MobileTextfield {...field} error={fieldState.error?.message} />
                        </>
                      )}
                    />
                    <div className='p-2 lg:ml-4 ntb:ml-4'> hodin</div>
                  </div>
                </div>
              </div>
              <HorizontalRow />
              <div className='grid grid-cols-2 mb-4'>
                <div className='font-bold text-text ml-2 mr-3 ml-10'>Finální cena bez DPH</div>
                <Controller
                  control={control}
                  name='serviceTotalPrice'
                  render={({ field: { value } }) => (
                    <>
                      {value && !isNaN(value)
                        ? dotToComma(value.toString())
                            .replace(/\s/g, '')
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
                        : 0}
                    </>
                  )}
                />
                &nbsp;Kč
              </div>
              <HorizontalRow />
              <div className='grid grid-cols-2 mb-4'>
                <div className='font-bold text-text ml-2 mr-3 ml-10'>Finální cena s DPH</div>
                {finalPriceWithDph
                  ? dotToComma(finalPriceWithDph.toFixed(2).toString())
                      .replace(/\s/g, '')
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
                  : 0}
                &nbsp;Kč
              </div>
              <HorizontalRow />
              <div className='grid grid-cols-2 mb-4 mr-2 items-center'>
                <div className='font-bold text-text ml-2 mr-3 ml-10'>Metoda zaplacení</div>

                <Controller
                  control={control}
                  name='servicePaymentMethodId'
                  render={({ field }) => (
                    <SelectMobile {...field}>
                      {paymentMethods.map((paymentMethod: PaymentMethodResponse) => (
                        <option key={paymentMethod.id} value={paymentMethod.id}>
                          {paymentMethod.name}
                        </option>
                      ))}
                    </SelectMobile>
                  )}
                />
              </div>
              <HorizontalRow />
              <div className='grid grid-cols-2 mb-4 items-center'>
                <div className='font-bold text-text ml-2 mr-3 mb-2 ml-10'>Foto</div>

                <div className='mb-4'>
                  <div className='max-h-72  overflow-x-hidden overflow-y-auto mb-4 md:grid md:grid-cols-2 lg:grid lg:grid-cols-2 ntb:grid ntb:grid-cols-2'>
                    {selectedFiles?.length > 0 &&
                      selectedFiles.map((id, fileIndex) => (
                        <div key={id}>
                          <div className='flex'>
                            <div className='flex justify-center'>
                              <button
                                onClick={() => {
                                  setPhotoDetailToShow(fileIndex);
                                }}
                              >
                                <Image id={id} className='h-32 w-32 mb-2 border border-border rounded' />
                              </button>
                            </div>
                            <div>
                              <button
                                onClick={() => {
                                  setSelectedFiles(remove(fileIndex, 1, selectedFiles));
                                }}
                              >
                                <div className='ml-2'>
                                  <BinIcon />
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <Uploader onUpload={onUpload} onUploaderStateChange={setIsUploading} />
                </div>
              </div>
              <HorizontalRow />
              <div className='md:grid md:grid-cols-2 mb-4 mr-2 items-center lg:grid lg:grid-cols-2 ntb:grid ntb:grid-cols-2'>
                <div className='flex'>
                  <div className='font-bold text-text ml-2 mr-3 ml-10'>Podpis technika*</div>
                  <div onClick={() => setIsOpenTechnicianSign(!isOpenTechnicianSign)}>
                    {isOpenTechnicianSign ? <BlackUpIcon /> : <BlackDownIcon />}
                  </div>
                </div>
                {isOpenTechnicianSign ? (
                  <Controller
                    control={control}
                    name='orderSignatureTechnician'
                    render={({ field }) => <Signature handleSign={field.onChange} />}
                  />
                ) : null}
              </div>
              <HorizontalRow />
              <div className='mb-4 mr-2 items-center md:grid md:grid-cols-2 lg:grid lg:grid-cols-2 ntb:grid ntb:grid-cols-2'>
                <div className='flex'>
                  <div className='font-bold text-text ml-2 mr-3 ml-10'>Podpis zákazníka*</div>
                  <div onClick={() => setIsOpenCustomerSign(!isOpenCustomerSign)}>
                    {isOpenCustomerSign ? <BlackUpIcon /> : <BlackDownIcon />}
                  </div>
                </div>
                {isOpenCustomerSign ? (
                  <Controller
                    control={control}
                    name='orderSignatureCustomer'
                    render={({ field }) => <Signature handleSign={field.onChange} />}
                  />
                ) : null}
              </div>
            </TechnicianFinishPageCard>
            <div className='mt-5 grid-cols-2 place-items-center'>
              <div className='flex mb-6'>
                <div className='md:p-2 lg:p-2 ntb:p-2'>
                  <TechnicianEditButton onClick={() => navigate({ pathname: '/technician-order' })}>
                    Zpět
                  </TechnicianEditButton>
                </div>

                <div className='md:p-2 lg:p-2 ntb:p-2'>
                  <TechnicianRowButton
                    type='submit'
                    disabled={isSubmitting || isUploading}
                    loading={isSubmitting || isUploading}
                  >
                    Dokončit
                  </TechnicianRowButton>

                  <Spinner />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      <Modal isOpen={isModalSucces} hideButtons>
        <div>Zakázka byla dokončena.</div>
      </Modal>

      <Modal isOpen={!(photoDetailToShow === undefined)} hideButtons>
        <div className='flex justify-end'>
          <div className='border border-primary rounded bg-primary w-8 h-8 flex justify-center self-center pr-2 text-white'>
            <button onClick={() => setPhotoDetailToShow(undefined)}>
              <ClearIcon />
            </button>
          </div>
        </div>
        <div className='flex justify-center'>
          <div className='mr-2 flex align-center'>
            <button
              onClick={() => {
                !(photoDetailToShow === undefined) &&
                  setPhotoDetailToShow(photoDetailToShow === 0 ? selectedFiles.length - 1 : photoDetailToShow - 1);
              }}
            >
              <ArrowLeft />
            </button>
          </div>
          {!(photoDetailToShow === undefined) && (
            <Image id={selectedFiles[photoDetailToShow]} className='h-9/12 w-9/12 border border-border rounded' />
          )}
          <div className='ml-2 flex align-center'>
            <button
              onClick={() => {
                !(photoDetailToShow === undefined) &&
                  setPhotoDetailToShow(photoDetailToShow === selectedFiles.length - 1 ? 0 : photoDetailToShow + 1);
              }}
            >
              <ArrowRight />
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TechnicianOrderFinish;
