import { Link } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import Textfield from 'src/components/Textfield';
import logo from '../../pictures/logo.png';
import GoBackIcon from 'src/components/Icons/GoBackIcon';
import { useOldApiClient } from 'src/api';
import { useState } from 'react';

type ForgotPassword = {
  email: string;
};

function ForgotPasswordPage(): React.ReactElement {
  const apiClient = useOldApiClient();
  const { handleSubmit, control } = useForm<ForgotPassword>();

  const [passwordWasSend, setPasswordWasSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<ForgotPassword> = async (data) => {
    setIsLoading(true);
    try {
      const response = await apiClient.post('auth/forgotten-password', {
        userEmail: data.email.toLocaleLowerCase().trim(),
      });
      if (response.status === 200) {
        setPasswordWasSend(true);
      }
    } catch (error) {
      console.error('test', error);
    }
  };

  //To do - refactor disabled button

  return (
    <div className='bg-background grid place-items-center h-screen'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid place-items-center'>
          <img src={logo} className='App-logo' alt='logo' />

          <div className=' text-4xl font-medium text-text mb-2 mt-8'>Zapomenuté heslo</div>

          <Card>
            <Link to='/login'>
              <GoBackIcon />
            </Link>

            <div className='ml-8 mt-2'>
              <div className='text-text'>E-mail:</div>
              <Controller
                control={control}
                name='email'
                render={({ field }) => (
                  <>
                    <Textfield {...field} />
                  </>
                )}
              />
            </div>
            <div className='text-primary text-sm flex justify-end mr-8 mt-2'>
              {passwordWasSend && 'Heslo bylo zasláno na email.'}
            </div>
            <div className='ml-8 mt-7 mb-8'>
              <Button disabled={isLoading} type='submit'>
                Poslat email
              </Button>
            </div>
          </Card>
        </div>
      </form>
    </div>
  );
}

export default ForgotPasswordPage;
